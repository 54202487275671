/*********************************************\
		Desktop First Method
\*********************************************/
/* Medium Devices, Notebooks */
@media only screen and (max-width : 1261px) {
	.header {
		.logo {
			img {
				width: 170px;
			}
		}
		.phones {
			.phone1 {
				margin-right: 20px;
			}
		}
	}

	.sec1 {
		padding-top: 70px;
		padding-bottom: 70px;
		.video {
			height: 220px;
		}
	}

	.sec2 {
		padding-top: 70px;
		padding-bottom: 70px;
		&:before {
			right: calc(50% + 80px);
		}
	}

	.sec3 {
		padding-top: 70px;
		padding-bottom: 70px;
		.sec-title {
			margin-bottom: 30px;
		}
		ul {
			.item {
				margin-bottom: 30px;
			}
		}
	}

	.sec4 {
		padding-top: 70px;
		padding-bottom: 70px;
		.program-title {
			font-size: 35px;
			line-height: 37px;
		}
		&:before {
			right: calc(50% + 180px);
		}
		.module {
			padding-left: 20px;
			.module-title {
				display: block;
				font-size: 26px;
				line-height: 30px;
				padding-left: 100px;
				margin-bottom: 50px;
			}
			.module-number {
				margin-bottom: 30px;
				&:before {
					right: auto;
					left: 0;
					top: 90px;
				}
			}
			ul {
				max-width: 100%;
				li {
					font-size: 18px;
					line-height: 20px;
					margin-bottom: 15px;
				}
			}
		}
		.bottom-block {
			padding-top: 40px;
			margin-top: 40px;
			.bottom-text {
				font-size: 20px;
				line-height: 25px;
			}
		}
	}

	.sec5  {
		padding-top: 70px;
		padding-bottom: 70px;
		.sec-title {
			text-align: center;
			margin-bottom: 30px;
			br {display: none;}
		}
		.item {
			.item-text {
				max-width: 100%;
			}
		}
	}

	.sec6 {
		padding-top: 70px;
		padding-bottom: 70px;
		.sec-title {
			font-size: 45px;
			line-height: 52px;
		}
		.slide {
			background-size: contain;
			min-height: 300px;
		}
		.slider {
			.slick-next {
				right: 275px;
			}
			.slick-prev {
				left: 310px;
			}
		}
	}

	.sec7 {
		padding-top: 70px;
		padding-bottom: 70px;
		.speakers {
			width: 500px;
			margin: 0 auto;
			.speaker {
				margin: 0 20px;
			}
			.slick-next {
				right: 185px;
			}
			.slick-prev {
				left: 220px;
			}
		}
	}

	.sec8 {
		padding-top: 70px;
		padding-bottom: 70px;
		.packages-desktop {
			display: none;
		}
		.packages-mobile {display: block;}
		.packages-mobile {
			.block-title {
				max-width: 100%;
				// text-align: center;
				margin-bottom: 30px;
			}
			.package {
				max-width: 320px;
				padding-top: 80px;
				&.online {
					background: url(../img/online.png) 0 0 no-repeat;
					background-size: 181px auto;
				}
				&.offline {
					background: url(../img/offline.png) 0 0 no-repeat;
					background-size: 181px auto;
				}
				.package-title {
					color: #f75858;
					font-family: "Bebas Neue";
					font-size: 30px;
					font-weight: 400;
					line-height: 40px;
				}
				ul {
					li {
						color: #000000;
						font-family: "SFUI Text";
						font-size: 15px;
						font-weight: 400;
						list-style-type: disc;
						margin-bottom: 10px;
						&.none {
							opacity: 0;
						}
					}
				}
				.additional-sm {
					margin-top: 50px;
					li {
						list-style-type: none;
						position: relative;
						br {display: none;}
						&:before {
							content:"";
							width: 27px;
							height: 20px;
							background: url(../img/check-red.svg) 0 0 no-repeat;
							display: block;
							position: absolute;
							left: -40px;
							top: 5px;
						}
						span {
							color: #ffffff;
							font-family: "Bebas Neue";
							font-size: 19px;
							font-weight: 700;
							display: inline-block;
							background-color: #e25050;
							width: 120px;
							line-height: 29px;
							text-align: center;
							position: absolute;
							top: -30px;
							left: 0;
						}
					}
				}
				.package-price-block {
					margin-top: 40px;
					.price-now-text,
					.price-usd {
						display: inline-block;
						color: #000000;
						font-family: "Bebas Neue";
						font-size: 29px;
						font-weight: 400;
						line-height: 40px;
					}
					.price-usd {
						color: #e25050;
					}
					.price {
						display: block;
						max-width: 100%;
					}
				}
			}


		}
	}

	.sec9 {
		padding-top: 70px;
		padding-bottom: 100px;
		.videreviews-slider {
			width: 500px;
			margin: 0 auto;
			.slick-next {
				right: 185px;
			}
			.slick-prev {
				left: 220px;
			}
			.videoreview  {
				margin: 0 20px;
			}
		}
	}

	.sec10 {
		padding-top: 70px;
		padding-bottom: 70px;
		.sec-title {
			text-align: center;
			margin-bottom: 30px;
			br {display: none;}
		}
	}
}



/* Extra Small Devices, Phones */
@media only screen and (max-width : 750px) {

	.btn {
		padding: 0 25px;
		font-size: 14px;
		line-height: 58px;
	}

	/*Disable Animation on Mobile Devices*/
	.animated {
		/*CSS transitions*/
		-o-transition-property: none !important;
		-moz-transition-property: none !important;
		-ms-transition-property: none !important;
		-webkit-transition-property: none !important;
		transition-property: none !important;
		/*CSS transforms*/
		-o-transform: none !important;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;
		/*CSS animations*/
		-webkit-animation: none !important;
		-moz-animation: none !important;
		-o-animation: none !important;
		-ms-animation: none !important;
		animation: none !important;
	}

	.header {
		background: #d4d1d8 url(../img/header-sm.png) 50% 0 no-repeat;
		background-size: cover;
		min-height: auto;
		padding-bottom: 30px;
		.logo {
			display: inline-block;
			height: 34px;
			width: 25px;
			background: url(../img/logo.png) 0 0 no-repeat;
			background-size: 169px;
			margin-top: 15px;
			img {
				display: none;
			}
		}
		.phones {
			position: relative;
			.phone {
				display: block;
				font-size: 17px;
				line-height: 32px;
				text-align: left;
				margin: 0;
				span {
					line-height: 20px;
					font-size: 12px;
				}
				&.phone1 {
					background: none;
					padding: 0;
				}
			}
			.callback-btn {
				position: absolute;
				top: -5px;
				left: -80px;
				width: 68px;
				height: 68px;
				border-radius: 50%;
				overflow: hidden;
				color: transparent;
				background-color: #fff;
				border: none;
				background-position: 50%;
				background-repeat: no-repeat;
				background-position: 50%;
				background-image: url(../img/phone-icon.png);
			}
		}
		.topline {
			margin-bottom: 20px;
		}
		.play-icon {
			margin: 0 auto;
			width: 90px;
			height: 90px;
			background-size: 90px;
			border-radius: 50%;
		}
		.title {
			margin-top: 10px;
			font-size: 45px;
			line-height: 39px;
			margin-bottom: 0;
			span {
				font-size: 24px;
				line-height: 33px;
				transform: translateY(-8px);
			}
		}
		.undertitle {
			margin-top: 10px;
			font-size: 21px;
			line-height: 20px;
			margin-bottom: 20px;
		}
		.desc {
			font-size: 16px;
			line-height: 22px;
			margin-bottom: 20px;
		}
		.date-block {
			margin-bottom: 20px;
			.date {
				font-size: 23px;
				line-height: 25px;
				margin-bottom: 5px;
			}
			.info {
				font-size: 14px;
				line-height: 17px;
				max-width: 100%;
			}
		}
		.info-block {
			margin-top: 25px;
			padding-top: 0;
			border: none;
			.text {
				max-width: 100%;
				margin-bottom: 10px;
			}
		}
	}

	.sec1 {
		padding-top: 50px;
		padding-bottom: 50px;
		.sec-title {
			font-size: 32px;
			line-height: 30px;
			margin-bottom: 29px;
			span {
				font-size: 22px;
			}
		}
		.video {
			height: 180px;
			margin-bottom: 30px;
			.play-icon {
				font-size: 16px;
				line-height: 20px;
				padding-right: 50px;
				text-align: left;
				padding-top: 20px;
				padding-bottom: 20px;
				background-size: 75px 75px;
				background-position: 45px 50%;
			}
		}
		.text-block {
			.text {
				font-size: 16px;
				line-height: 20px;
				&:before {
					display: none;
				}
			}
		}
	}

	.sec2 {
		&:before {
			display: none;
		}
		padding-top: 100px;
		position:relative;
		.block-title {
			font-size: 32px;
			line-height: 30px;
			max-width: 100%;
			margin-bottom: 28px;
			position: absolute;
			top: -380px;
		}
		.line {
			display: none;
		}
		.zp-block {
			.text {
				text-align: center;
				max-width: 100%;
				margin-bottom: 5px;
				br {display: none;}
			}
			.summ {
				text-align: center;
				font-size: 18px;
				line-height: 21px;
				br {display: none;}
			}
		}
		.btn {
			margin: 0 auto;
			width: 253px;
			display: block;
			margin-top: 15px;
		}
		.text2 {display: none;}
		.tabs-menu {
			margin-top: 20px;
			ul {
				margin: 0 auto;
				display: block;
				.tab-title {
					padding-left: 21px;
					padding-right: 21px;
				}
			}
		}
		.tabs-content {
			.tab{
				height: 200px;
			}
		}
	}

	.sec3 {
		padding-top: 50px;
		padding-bottom: 50px;
		.sec-title {
			font-size: 30px;
			line-height: 30px;
		}
		ul {
			.item {
				padding-left: 80px;
				font-size: 15px;
				line-height: 20px;
			}
		}
	}

	.sec4 {
		&:before {right: calc(50% + 76px);}
		.program-title {
			font-size: 18px;
			line-height: 20px;
			margin-bottom: 25px;
		}
		.program-navigation {
			ul {
				.tab-link {
					font-size: 13px;
					line-height: 38px;
					border-radius: 0;
					width: 95px;
				}
				.ekvator,
				.defence {
					&:before {
						top: -2px;
					}
					span {display: none;}
				}
			}
		}
		.module {
			padding-left: 20px;
			.module-number {
				font-size: 21px;
				line-height: 20px;
				letter-spacing: 0.42px;
				margin-right: 0;
				padding-left: 80px;
				&:before {
					width: 54px;
					height: 54px;
					background-size: 54px;
					top: 10px;
					left: 0;
				}
			}
			.module-title {
				padding: 0;
				font-size: 16px;
				line-height: 20px;
				font-weight: 700;
				margin-bottom: 20px;
			}
			ul {
				li {
					font-size: 16px;
					line-height: 20px;
					margin-bottom: 6px;
				}
			}
		}
		.bottom-block {
			padding-top: 20px;
			margin-top: 20px;
			.bottom-text {
				font-size: 15px;
				line-height: 20px;
				margin-bottom: 20px;
			}
		}
		.btn {
			line-height: 21px;
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}

	.sec5 {
		padding-top: 50px;
		padding-bottom: 50px;
		.sec-title {
			font-size: 32px;
			line-height: 30px;
		}
		.item {
			margin-left: 40px;
			padding-bottom: 30px;
			&:after {
				left: -31px;
			}
			.item-number {
				left: -50px;
			}
			.item-title {
				font-size: 20px;
				line-height: 25px;
				margin-bottom: 10px;
			}
			.item-text {
				font-size: 16px;
				line-height: 21px;
				letter-spacing: -0.32px;
			}
		}
	}

	.sec6 {
		padding-top: 50px;
		padding-bottom: 100px;
		.sec-title {
			font-size: 32px;
			line-height: 30px;
			br {display: none;}
		}
		.desc {
			font-size: 16px;
			line-height: 21px;
			margin-bottom: 40px;
		}
		.slider {
			width: 300px;
			margin: 0 auto;
			.slide {
				width: 100%;
				margin: 0 auto;
				min-height: 150px;
			}
			.slick-dots {
				&:before {
					left: 53%;
				}
			}
			.slick-prev {left: 120px;}
			.slick-next {right: 85px;}
		}
	}

	.sec7 {
		padding-top: 50px;
		padding-bottom: 80px;
		.sec-title {
			font-size: 32px;
			line-height: 29px;
			margin-bottom: 20px;
		}
		.speakers {
			width: 100%;
			.speaker {
				margin: 0 auto;
				.speaker-info {
					height: 285px;
					padding: 5px;
					li {
						font-size: 14px;
						line-height: 16px;
						padding-left:5px;
					}
				}
				.speaker-info-wrap {
					height: auto;
					margin-bottom: 10px;
					.speaker-photo {
						width: 100%;
						height: 285px;
					}
					.speaker-prof {
						position: relative;
						transform: none;
						width: 100%;
						padding: 5px;
						font-size: 15px;
						line-height: 20px;
						bottom: auto;
						right: auto;
						box-shadow: 0 7px 24px rgba(97, 97, 97, 0.29);
						margin-bottom: 10px;
					}
				}
				.speaker-name {
					font-size: 21px;
					line-height: 25px;
				}
			}
		}
		.speakers {
			width: 300px;
			margin: 0 auto;
			.slide {
				width: 100%;
				margin: 0 auto;
				min-height: 150px;
			}
			.slick-dots {
				&:before {
					left: 53%;
				}
			}
			.slick-prev {left: 120px;}
			.slick-next {right: 85px;}
		}
	}

	.sec8 {
		padding-bottom: 0px;
		.packages-mobile {
			.block-title {
				font-size: 32px;
				line-height: 30px;
			}
			.package-price-block {
				margin-top: 0;
			}
			.package {
				ul {
					li.none {
						display: none;
					}
				}
			}
		}
	}

	.sec9 {
		padding-top: 50px;
		padding-bottom: 80px;
		.sec-title {
			font-size: 32px;
			line-height: 30px;
			margin-bottom: 20px;
		}
		.videreviews-slider {
			width: 100%;
			.slick-dots {
				&:before {
					left: 53%;
				}
			}
			.slick-prev {left: 120px;}
			.slick-next {right: 85px;}
			.videoreview {
				max-width: auto;
				width: 100%;
				margin: 0 auto;
				.video {
					height: 209px;
					margin-bottom: 25px;
				}
				.author-name {
					font-size: 21px;
					line-height: 27px;
					margin-bottom: 10px;
				}
				.author-info {
					font-size: 16px;
					line-height: 20px;
				}
			}
		}
	}

	.sec10 {
		padding-top: 50px;
		padding-bottom: 50px;
		.sec-title {
			font-size: 32px;
			line-height: 30px;
			margin: 0;
			margin-bottom: 25px;
		}
		.question-block {
			padding-left: 17px;
			padding-top: 17px;
			padding-bottom: 17px;
			padding-right: 17px;
			&.active {
				.icon {background-size: 29px;}

			}
			.icon {
				width: 29px;
				height: 29px;
				background-size: 29px;
				top: 10px;
				right: 5px;

			}
			.question {
				font-size: 16px;
				line-height: 22px;
				max-width: 250px;
			}
			.answer {
				font-size: 15px;
				line-height: 20px;
			}
		}
		.btn-wrap {
			margin-top: 35px;
		}
	}
}


/* Custom, iPhone Retina */
@media only screen and (max-width : 340px) {
		.container {
		padding: 0;
		overflow: hidden;
		width: 320px;}
}


/*********************************************\
		Mobile First Method
\*********************************************/


/* Extra Small Devices, Phones */
@media only screen and (min-width : 320px) {

}


/* Small Devices, Tablets */
@media only screen and (min-width : 720px) {

}


/* Medium Devices, Desktops */
@media only screen and (min-width : 960px) {

}


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1130px) {

}